.nijjar-legacy-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.nijjar-legacy-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.nijjar-legacy-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.nijjar-legacy-left-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.nijjar-legacy-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-icon {
  width: 36px;
  height: 36px;
}
.nijjar-legacy-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.nijjar-legacy-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.nijjar-legacy-link1 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.nijjar-legacy-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.nijjar-legacy-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.nijjar-legacy-text03 {
  font-size: 18px;
}
.nijjar-legacy-text04 {
  font-size: 18px;
}
.nijjar-legacy-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.nijjar-legacy-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.nijjar-legacy-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-icon2 {
  width: 24px;
  height: 24px;
}
.nijjar-legacy-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-link2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nijjar-legacy-link3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nijjar-legacy-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.nijjar-legacy-hero {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-color: grey;
  background-image: url("/playground_assets/mikeandpatty-1500w.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-blend-mode: multiply;
}
.nijjar-legacy-content-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  justify-content: center;
}
.nijjar-legacy-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.nijjar-legacy-heading {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: var(--dl-space-space-fiveunits);
  text-transform: none;
  text-decoration: none;
}
.nijjar-legacy-text07 {
  color: rgb(247, 247, 247);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.nijjar-legacy-text14 {
  font-size: 22px;
}
.nijjar-legacy-cta-btn1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.nijjar-legacy-legacy {
  width: 100%;
  display: flex;
  padding: 10px;
  max-width: var(--dl-size-size-maxcontent);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 22px;
}
.nijjar-legacy-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.nijjar-legacy-text19 {
  margin-bottom: var(--dl-space-space-fourunits);
}
.nijjar-legacy-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.nijjar-legacy-features-card {
  width: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nijjar-legacy-iframe {
  flex: 1;
  width: 600px;
  height: 400px;
}
.nijjar-legacy-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 26px;
}
.nijjar-legacy-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.nijjar-legacy-text22 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nijjar-legacy-cards-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.nijjar-legacy-features-card1 {
  width: 45%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nijjar-legacy-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-heading1 {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.nijjar-legacy-link5 {
  display: contents;
}
.nijjar-legacy-image {
  width: 311px;
  height: 164px;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.nijjar-legacy-text25 {
  font-size: 12px;
  margin-top: 5px;
}
.nijjar-legacy-text26 {
  font-weight: 700;
}
.nijjar-legacy-text31 {
  color: var(--dl-color-gray-500);
  font-size: 18px;
  margin-top: 5px;
  text-align: left;
  margin-bottom: 5px;
}
.nijjar-legacy-cta-btn2 {
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.nijjar-legacy-features-card2 {
  width: 45%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nijjar-legacy-text-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-heading2 {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  margin-bottom: var(--dl-space-space-tripleunit);
  text-transform: none;
  text-decoration: none;
}
.nijjar-legacy-link6 {
  display: contents;
}
.nijjar-legacy-image1 {
  width: 307px;
  height: 205px;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.nijjar-legacy-text39 {
  font-size: 12px;
  padding-top: 5px;
}
.nijjar-legacy-text40 {
  font-weight: 700;
}
.nijjar-legacy-text41 {
  font-weight: 700;
}
.nijjar-legacy-text45 {
  color: var(--dl-color-gray-500);
  font-size: 18px;
  margin-top: 5px;
  text-align: left;
  margin-bottom: 5px;
}
.nijjar-legacy-cta-btn3 {
  color: var(--dl-color-gray-white);
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  text-decoration: none;
  background-color: var(--dl-color-gray-black);
}
.nijjar-legacy-features-card3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nijjar-legacy-text-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nijjar-legacy-html-node {
  width: 1103px;
  align-self: center;
}
.nijjar-legacy-features-card4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.nijjar-legacy-text-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.nijjar-legacy-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.nijjar-legacy-code-embed {
  width: 1103px;
  align-self: center;
}
.nijjar-legacy-tenant-stories {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: 12px;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.nijjar-legacy-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.nijjar-legacy-text61 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nijjar-legacy-text64 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.nijjar-legacy-cards-container2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.nijjar-legacy-service-card {
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.nijjar-legacy-text67 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nijjar-legacy-iframe1 {
  width: 320px;
  height: 200px;
}
.nijjar-legacy-service-card1 {
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.nijjar-legacy-text68 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nijjar-legacy-iframe2 {
  width: 320px;
  height: 200px;
}
.nijjar-legacy-service-card2 {
  width: 33%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.nijjar-legacy-text69 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.nijjar-legacy-iframe3 {
  width: 320px;
  height: 200px;
}
.nijjar-legacy-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
@media(max-width: 1200px) {
  .nijjar-legacy-text07 {
    color: rgb(247, 247, 247);
  }
  .nijjar-legacy-text14 {
    font-size: 22px;
  }
  .nijjar-legacy-legacy {
    align-self: center;
    align-items: center;
  }
  .nijjar-legacy-container2 {
    justify-content: center;
  }
  .nijjar-legacy-cards-container {
    align-self: center;
    justify-content: center;
  }
  .nijjar-legacy-features-card {
    flex: none;
    width: auto;
    justify-content: center;
  }
  .nijjar-legacy-iframe {
    flex: 1;
    width: 600px;
    height: 400px;
    align-self: center;
  }
  .nijjar-legacy-features {
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .nijjar-legacy-container3 {
    justify-content: center;
  }
  .nijjar-legacy-heading2 {
    font-size: 24px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .nijjar-legacy-text47 {
    font-style: normal;
    font-family: Raleway;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .nijjar-legacy-text48 {
    font-style: normal;
    font-family: Raleway;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .nijjar-legacy-text51 {
    font-style: normal;
    font-family: Raleway;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .nijjar-legacy-text52 {
    font-style: normal;
    font-family: Raleway;
    font-weight: 400;
    line-height: 1.55;
    text-transform: none;
    text-decoration: none;
  }
  .nijjar-legacy-text64 {
    color: var(--dl-color-gray-500);
  }
}
@media(max-width: 991px) {
  .nijjar-legacy-hero {
    flex-direction: column-reverse;
  }
  .nijjar-legacy-content-container {
    flex-direction: column-reverse;
  }
  .nijjar-legacy-hero-text {
    width: 80%;
  }
  .nijjar-legacy-iframe {
    flex: 1;
  }
  .nijjar-legacy-html-node {
    width: 700px;
  }
  .nijjar-legacy-code-embed {
    width: 700px;
  }
  .nijjar-legacy-service-card {
    width: 65%;
  }
  .nijjar-legacy-iframe1 {
    width: 270;
  }
  .nijjar-legacy-service-card1 {
    width: 65%;
  }
  .nijjar-legacy-iframe2 {
    width: 270;
  }
  .nijjar-legacy-service-card2 {
    width: 65%;
  }
  .nijjar-legacy-iframe3 {
    width: 270;
  }
}
@media(max-width: 767px) {
  .nijjar-legacy-burger-menu {
    display: none;
    padding: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .nijjar-legacy-icon {
    fill: var(--dl-color-gray-white);
  }
  .nijjar-legacy-links-container {
    display: none;
  }
  .nijjar-legacy-container2 {
    height: auto;
  }
  .nijjar-legacy-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .nijjar-legacy-features-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nijjar-legacy-cards-container1 {
    align-items: center;
    flex-direction: column;
  }
  .nijjar-legacy-features-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nijjar-legacy-features-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nijjar-legacy-features-card3 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nijjar-legacy-html-node {
    width: 600px;
  }
  .nijjar-legacy-features-card4 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .nijjar-legacy-code-embed {
    width: 600px;
  }
  .nijjar-legacy-heading-container {
    width: 100%;
  }
  .nijjar-legacy-cards-container2 {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .nijjar-legacy-service-card {
    width: 65%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .nijjar-legacy-service-card1 {
    width: 65%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .nijjar-legacy-service-card2 {
    width: 65%;
  }
}
@media(max-width: 479px) {
  .nijjar-legacy-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .nijjar-legacy-mobile-menu {
    display: none;
  }
  .nijjar-legacy-link2 {
    font-size: 18px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }
  .nijjar-legacy-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .nijjar-legacy-content-container {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .nijjar-legacy-hero-text {
    width: 100%;
  }
  .nijjar-legacy-heading {
    font-size: 1.5rem;
  }
  .nijjar-legacy-legacy {
    padding-top: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .nijjar-legacy-features-card {
    width: 100%;
  }
  .nijjar-legacy-iframe {
    width: 360px;
    height: 250px;
  }
  .nijjar-legacy-features {
    padding-top: var(--dl-space-space-doubleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }
  .nijjar-legacy-cards-container1 {
    width: auto;
  }
  .nijjar-legacy-features-card1 {
    width: 100%;
  }
  .nijjar-legacy-features-card2 {
    width: 100%;
  }
  .nijjar-legacy-html-node {
    width: 300px;
  }
  .nijjar-legacy-features-card4 {
    justify-content: flex-start;
  }
  .nijjar-legacy-code-embed {
    width: 300px;
  }
  .nijjar-legacy-tenant-stories {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .nijjar-legacy-service-card {
    width: 100%;
  }
  .nijjar-legacy-iframe1 {
    width: 320;
  }
  .nijjar-legacy-service-card1 {
    width: 100%;
  }
  .nijjar-legacy-iframe2 {
    width: 320;
  }
  .nijjar-legacy-service-card2 {
    width: 100%;
  }
  .nijjar-legacy-iframe3 {
    width: 320;
  }
}
